/**
 * Created by yihou on 17/5/2016.
 */

var offCanvasMenu = function(setting){
    /*store caller's details*/
    var root = {
        isOpen : false
    };
    root.menu = $(this);
    root.scrollBarWidth = getScrollBarWidth();
    setting = setting || null;

    setting.panel = setting.panel || null;
    setting.windowWidth = setting.windowWidth || false;

    /*check menu is fixed size or follow window width*/
    if(typeof setting.menuWidth != 'undefined' && setting.windowWidth == true)
        root.menuWidthFixed = true;
    else
        root.menuWidthFixed = false;

    setting.menuWidth = setting.menuWidth || root.menu.width();
    setting.position = setting.position || 'left';

    /*must have panel to initiate*/
    if(setting.panel  == null){
        return false;
    }

    function movePanel(action){
        action = action || 'open';
        var menuScrollBarWidth = $(window)[0].outerHeight > $(window).height() && setting.position == 'right'? root.scrollBarWidth : 0;
        var translateX_dist = setting.position == 'left'? setting.menuWidth + menuScrollBarWidth : -setting.menuWidth + menuScrollBarWidth;
        switch(action){
            case 'open':
                setting.panel.css(transformPrefixes('translate3d('+translateX_dist+'px, 0, 0)'));
                setting.panel.unbind('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd')
                    .on('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function(){
                    setting.panel.addClass('offCanvas-panel-open');
                    if(typeof setting.onOpen == 'function')
                        setting.onOpen();
                });
                root.isOpen = true;
                break;
            case 'close':
                setting.panel.css(transformPrefixes('translate3d(0,0,0)'));
                setting.panel.unbind('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd')
                    .on('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function(){
                    setting.panel.removeClass('offCanvas-panel-open');
                    root.menu.css('opacity', 0);
                    if(typeof setting.onClose == 'function')
                        setting.onClose();
                });
                root.isOpen = false;
                break;
        }
    }
    function moveMenu(action){
        action = action || 'open';
        var menuScrollBarWidth = root.menu[0].scrollHeight > root.menu.height() && setting.position == 'left'? root.scrollBarWidth : 0;
        var translateX_dist = setting.position == 'left'? (-setting.menuWidth)+ menuScrollBarWidth : setting.menuWidth+ menuScrollBarWidth;
        switch(action){
            case 'open':
                root.menu.css('opacity', 1);
                root.menu.css(transformPrefixes('translate3d(0,0,0)'))
                    .addClass('active');
                break;
            case 'close':
                root.menu.css(transformPrefixes('translate3d('+translateX_dist+'px,0,0)'))
                    .removeClass('active');
                break;
        }
    }
    function toggleMenu(){
        var isOpen = root.menu.hasClass('active');
        if(isOpen == true){
            moveMenu('close');
            movePanel('close');
        }else{
            moveMenu('open');
            movePanel('open');
        }
    }
    function openMenu(){
        moveMenu('open');
        movePanel('open');
    }
    function closeMenu(){
        moveMenu('close');
        movePanel('close');
    }
    function initMenu(){
        var _this = this;
        //root.menu.css('opacity', 1);
        setting.panel.addClass('offCanvas-panel');
        //moveMenu('close');
        $(window).resize(function(){
            resizeMenu();
        });
    }
    function resizeMenu(){
        /*update sizes*/
        root.scrollBarWidth = getScrollBarWidth();

        if( setting.windowWidth == true ){
            setting.menuWidth = $(window).width();
        }else{
            if(root.menuWidthFixed == false){
                setting.menuWidth = root.menu.width();
            }
        }
        if(root.menu.css('display') == 'none'){
            setting.menuWidth = 0;
        }
        /*update sizes to elements*/
        if(root.isOpen == true){
            movePanel('open');
        }else{
            moveMenu('close');
        }
    }
    $('a[href="#'+root.menu.attr('id')+'"]').click(function(){
        toggleMenu();
    });

    /*able to click panel to close it when its open*/
    setting.panel.click(function(){
        if($(this).hasClass('offCanvas-panel-open')){
            moveMenu('close');
            movePanel('close');
        }
    });

    initMenu();
    return {
        toggle: toggleMenu,
        openMenu: openMenu,
        closeMenu: closeMenu,
        resize: resizeMenu
    };
};

function transformPrefixes(val){
    return {
        '-webkit-transform': val,
        '-moz-transform': val,
        '-ms-transform': val,
        '-o-transform': val,
        'transform': val
    };
}

function getScrollBarWidth (selector) {
    selector = selector || null;
    if(selector == null){
        var inner = document.createElement('p');
        inner.style.width = "100%";
        inner.style.height = "200px";

        var outer = document.createElement('div');
        outer.style.position = "absolute";
        outer.style.top = "0px";
        outer.style.left = "0px";
        outer.style.visibility = "hidden";
        outer.style.width = "200px";
        outer.style.height = "150px";
        outer.style.overflow = "hidden";
        outer.appendChild (inner);

        document.body.appendChild (outer);
        var w1 = inner.offsetWidth;
        outer.style.overflow = 'scroll';
        var w2 = inner.offsetWidth;
        if (w1 == w2) w2 = outer.clientWidth;

        document.body.removeChild (outer);

        return (w1 - w2);
    }else{
        var outerWidth = selector.innerWidth();
        selector.append('<div id="test-scroll" style="width:100%; "></div>');
        var innerWidth = selector.find('#test-scroll').width();
        selector.find('#test-scroll').remove();
        return outerWidth- innerWidth;
    }

}
