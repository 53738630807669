$(function(){

    $('[data-toggle="tooltip"]').tooltip();

    jQuery.validator.setDefaults({
        errorElement: "span"
    });

    $('input:not(.hidden)').iCheck({
        checkboxClass: 'icheckbox_flat-orange',
        radioClass: 'iradio_flat-orange'
    });

    (function menuTab(){

        reposDropdown();
        $(window).resize(function(){
            reposDropdown();
        });

        $('.group-type-list .nav-tabs a').on('shown.bs.tab', function(){
            reposDropdown();
        });

        function reposDropdown(){
            $.fn.matchHeight._update()
            var winW = $(window).width();
            $('.group-type-list .tab-pane.active').find('.group').each(function(){
                var $this = $(this), $dropdown = $this.find('.dropdown');
                if($dropdown.width() > winW / 2) {
                    $dropdown.attr('style', 'min-width:' + winW / 2 + 'px');
                }
                if($dropdown.length > 0){
                    if($dropdown.width() + $this[0].offsetLeft > winW){
                        $dropdown.addClass('right');
                    }else{
                        $dropdown.removeClass('right');
                    }
                }
            });
        }

    })();

    if ($('#popUpsContainer').length) {
        setTimeout(function () {
            var items = [];
            $('#popUpsContainer').find('.popup').each(function () {
                items.push({
                    src: $(this)
                });
            });
            $.magnificPopup.open({
                items: items,
                type: 'inline',
                gallery: {
                    enabled: true
                }
            });
        }, 5000);
    }

    $('.btnCloseMagnific').click(function(){
        $.magnificPopup.close();
    });

    initSearchBar();

    function initSearchBar() {
        $('#search').click(function(){
            var $searchBar = $('#searchBar');
            if($(this).data('opened') != 1) {
                $(this).data('opened', 1);
                $(this).addClass('active');
                $(this).find('.fa').removeClass('fa-search').addClass('fa-times');
                $searchBar.fadeIn();
                $searchBar.find('input[type="text"]').focus();
            }else{
                $(this).data('opened', 0);
                $(this).removeClass('active');
                $(this).find('.fa').removeClass('fa-times').addClass('fa-search');
                $searchBar.fadeOut();
            }
        });
    }

    $('#menu nav li:has(ul)').doubleTapToGo();

    (function stickyHeader(){
        var $header = $('header'),
            $headerMain = $('.header-main'),
            $headerOffset = $('.header-main-offset'),
            $headerMenu = $('.header > .group-type-list'),
            offset = 30,
            bp = 992;

        checkScrollTop();
        $(window).resize(function(){
            if($(this).width() > bp){
                checkScrollTop();
            }else{
                removeSticky();
            }
        });
        $(window).scroll(function(){
            if($(this).width() > bp){
                checkScrollTop();
            }else{
                removeSticky();
            }
        });

        function checkScrollTop(){
            if ($(this).scrollTop() > $headerMain.height() + $headerMenu.height() ){
                // $headerMenu.css('top', $headerMain.height() + 'px');
                $headerOffset.css('height', (/*$headerMain.height() + */$headerMenu.height())+'px');
                $header.addClass("sticky");
            }else{
                removeSticky();
            }
        }

        function removeSticky(){
            $headerOffset.css('height', '0px');
            $header.removeClass("sticky");
        }

    })();

    (function numInput() {
        //num-input
        var $input = $('.quantity-control .num-input'),
            $minusBtn = $input.siblings('.num-minus'),
            $plusBtn = $input.siblings('.num-plus');

        $input.each(function(){
            var $this = $(this), _minAmount = $this.attr('min') || 1, _maxAmount = $this.attr('max') || 999;
            if(!$(this).closest('.quantity-control').hasClass('disabled')) {
                $this.on('plus', function () {
                    var _val = parseInt($this.val());
                    if (_val < _maxAmount) {
                        $this.val(++_val);
                        if ($this.val() > _minAmount) {
                            $this.siblings('.num-minus.disabled').removeClass('disabled');
                        } else {
                            $this.siblings('.num-minus:not(.disabled)').addClass('disabled');
                        }
                        if ($this.val() < _maxAmount) {
                            $this.siblings('.num-plus').removeClass('disabled');
                        } else {
                            $this.siblings('.num-plus:not(.disabled)').addClass('disabled');
                        }
                        $this.trigger('change');
                    }
                }).on('minus', function () {
                    var _val = parseInt($this.val());
                    if (_val > _minAmount) {
                        $this.val(--_val);
                        if ($this.val() > _minAmount) {
                            $this.siblings('.num-minus.disabled').removeClass('disabled');
                        } else {
                            $this.siblings('.num-minus:not(.disabled)').addClass('disabled');
                        }
                        if ($this.val() < _maxAmount) {
                            $this.siblings('.num-plus').removeClass('disabled');
                        } else {
                            $this.siblings('.num-plus:not(.disabled)').addClass('disabled');
                        }
                        $this.trigger('change');
                    }
                }).on('keyup', function () {
                    if (!$.isNumeric($(this).val()) || $(this).val() < _minAmount) {
                        $(this).val(_minAmount);
                        $this.trigger('change');
                    }
                });
            }
        });

        $minusBtn.click(function(){
            if(!$(this).closest('.quantity-control').hasClass('disabled')) {
                $(this).siblings('.num-input').trigger('minus');
            }
        });
        $plusBtn.click(function(){
            if(!$(this).closest('.quantity-control').hasClass('disabled')) {
                $(this).siblings('.num-input').trigger('plus');
            }
        });

    })();

    (function productShowOption(){
        var $productList = $('body').find('.half-hidden .product'),
            _breakPoint = 768,
            _resizeTimeout = null,
            winW = $(window).width();

        if($productList.length > 0 && !$productList[0].hasAttribute('data-mh') ){
            $productList.matchHeight();
        }
        $productList.find('h3').matchHeight();

        $.fn.matchHeight._afterUpdate = function(e, g) {
            var _height = 0, winW = $(window).width();

            if(winW < 992){
                $.fn.matchHeight._apply( $productList, { 'byRow': false });
            }else{
                $productList.each(function(){
                    var $this = $(this),
                        _isLast = $this.is(':last-child') && $this.is(':nth-child(5n+1)') ? true : false;
                    if($(this).closest('.product-row').find('.product').length == 1) {
                        $this.css('height', $this.height()+'px');
                    }
                    else if( _isLast == true){
                        $this.css('height', _height+'px');
                    }
                    else{
                        _height = $this.height();
                    }

                });
            }
        };

        $productList.hover(function(e){
            if(winW < _breakPoint ) return;
            var $this = $(this), $hidden_sec = $this.find('.col-action'), $content_sec = $this.find('.col-content'), $img_sec = $this.find('.img-cont'),
                _thisHeight = $this[0].scrollHeight,
                _hiddenHeight = $hidden_sec.length > 0 ? $hidden_sec[0].scrollHeight : 0,
                _contentHeight = $content_sec.length > 0 ? $content_sec[0].scrollHeight : 0,
                _imgHeight = $img_sec.length > 0 ? $img_sec[0].scrollHeight : 0,
                _offset = 30,
                _additional_height = $this.find('.review').length > 0 ? 24: 0;
            if($this.hasClass('grid')){
                $this.find('> div').css({
                    'height': (_contentHeight + _hiddenHeight + _imgHeight + _offset + _additional_height + 20 )+ 'px',
                    'padding-bottom': (_contentHeight + _hiddenHeight + _imgHeight + _offset - _thisHeight + _additional_height + 20) +'px'
                });
                $hidden_sec.css('height', _hiddenHeight +'px' );
                $content_sec.css('height', _contentHeight +'px' );
                if($this.closest('.tab-pane').length > 0) {
                    $this.closest('.tab-pane').css('min-height', (_contentHeight + _hiddenHeight + _imgHeight + _offset + _additional_height + 50));
                }
            }
        },
            function(){
            if(winW < _breakPoint ) return;

            var $this = $(this), $hidden_sec = $this.find('.col-action')
            /*, $option = $this.find('.product-option')*/;
            if($this.hasClass('grid')) {
                $this.find('> div').css({
                    'height': '100%',
                    'padding-bottom': '0'
                });

                $hidden_sec.css('height', 0);

                if($this.closest('.tab-pane').length > 0) {
                    $this.closest('.tab-pane').css('min-height', 'initial');
                }
            }
            /*if($option.length > 0)
             $option.select2('close');*/
        });

        $(window).resize(function(){
            winW = $(window).width();
            resizeProduct();
        });

        (function initResizeProduct(){
            $productList.find('h3').css('width', $productList.find('h3').width()+'px');
        })();
        function resizeProduct(){
            //$productList
            if(typeof _resizeTimeout != 'undefined'){
                clearTimeout(_resizeTimeout);
            }
            _resizeTimeout = setTimeout(function(){
                $productList.each(function(){
                    var $this = $(this), $hidden_sec = $this.find('.col-action'),
                        _hiddenHeight = $hidden_sec.length > 0? $hidden_sec[0].scrollHeight: 0;
                    if(winW < _breakPoint ){
                        $this.find('> div').css({
                            'padding-bottom': '0'
                        });
                    }else{
                        if($this.is(":hover") == false){
                            $this.find('h3').css('width', 'auto');
                            $this.find('> div').css({
                                'padding-bottom': '0'
                            });
                        }
                    }
                    if($this.is(":hover") == false){
                        $this.find('h3').css('width', $this.find('> div').outerWidth()-40+'px');
                    }
                });
                $.fn.matchHeight._update();
                _resizeTimeout = null;
            }, 500);

        }
    })();

    (function mobileMenu(){
        var $wrapper = $('#wrapper');
        var mobileMenu = offCanvasMenu.call($('#mobile-menu'), {
            panel: $wrapper,
            position: 'right'
        });

        /*mobile close btn*/
        $('#mobile-menu-back').click(function(e){
            e.preventDefault();
            mobileMenu.closeMenu();
        });

        var mobileCategory = offCanvasMenu.call($('#mobile-category-menu'), {
            panel: $wrapper,
            position: 'left'
        });

        $('#mobile-category-menu-back').click(function(e){
            e.preventDefault();
            mobileCategory.closeMenu();
        });
    })();

    (function mobileCategoryMenu(){
        var $mobileMenu = $('#mobile-category-menu'),
            $mobileTab = $('.mobile-category-tab-cont');

        tabPos();
        $(window).resize(function(){
            tabPos();
        });

        function tabPos(){
            /*reposition tabs */
            $mobileTab.css('top', $mobileTab.width()+'px');
        }

        $('.mobile-category-2-sub[data-category-for]').click(function(e){
            e.preventDefault();
            showThirdLvl.call($(this));
        });
        $('.mobile-category-3-sub-back').click(function(e){
            e.preventDefault();
            showThirdLvl.call($(this), 'false');
        });
        function showThirdLvl(option){
            option = option || 'true';
            if(option == 'true') {
                $mobileMenu.addClass('level-3-active');
                $mobileMenu.scrollTop(0);
                $('.mobile-category-2-sub-content[data-category-id="'+$(this).data('category-for')+'"]').addClass('active');
            }else {
                $mobileMenu.removeClass('level-3-active');
                $('.mobile-category-2-sub-content').removeClass('active');
            }
        }
    })();

    (function productDisplay(){
        var $btns = $('.product-listing-type button'),
            _winW = $(window).width(),
            _bp = 480,
            _displayType = readCookie('display') || 'grid';
        $btns.click(function(){
            var $this = $(this);
            if( !$this.hasClass('active') ){
                $this.addClass('active')
                    .siblings('button.active').removeClass('active');
                product_display($this.data('type'), true);
                _displayType = $this.data('type');
            }
        });

        $(window).resize(function(){
            _winW = $(window).width();
            if(_winW > _bp ){
                product_display();
            }else{
                if( _displayType != 'grid' ){
                    product_display('grid');
                }

            }
        });

    })();

    $('.datepicker, .date-bar .date').datepicker();

    (function initProductOptionDropDown(){
        $('.product-options-wrapper').on('change', '.product-option', function () {
            var selected_ajax = [];
            var _this = $(this);
            var product = _this.closest('.product-options-wrapper').data('product');
            if (_this.val()) {
                if($('#' + _this.attr('id')+'-error').length > 0) {
                    $('#' + _this.attr('id') + '-error').remove();
                    if($.fn.matchHeight){
                        $.fn.matchHeight._update();
                    }
                }
                _this.closest('.product-options-wrapper').find('.product-option').each(function () {
                    if ($(this).val()) {
                        selected_ajax.push($(this).data('id') + '=>' + $(this).val());
                    }
                });
                $.post(product_option_url, {
                    product: product,
                    selected_attributes: selected_ajax
                }, function (data) {
                    if (data.status) {
                        var options_html = "<option value=''>" + data.group + "</option>";
                        for (var i = 0; i < data.options.length; i++) {
                            if (data.options[i]) {
                                if (data.options[i].disabled) {
                                    options_html += "<option value='" + data.options[i].value + "' disabled>" + data.options[i].text + "</option>";
                                }
                                else {
                                    options_html += "<option value='" + data.options[i].value + "'>" + data.options[i].text + "</option>";
                                }
                            }
                        }
                        var first_select_element = true;
                        var after_changed = false;
                        _this.closest('.product-options-wrapper').find('.product-option').each(function () {
                            if (first_select_element && $(this).val() == "") {
                                $(this).html(options_html).attr('title', $(this).data('group')).attr('data-original-title', $(this).data('group'));
                                open_select(this);
                                first_select_element = false;
                            }
                            else {
                                if (after_changed) {
                                    $(this).attr('title', "{{ trans('store.product.option.select_first') }}").html('<option value="">' + $(this).data('group') + '</option>');
                                }
                                if ($(this).attr('id') == _this.attr('id')) {
                                    after_changed = true;
                                }
                            }
                        });
                    }
                    else if(data.image_id) {
                        $('.product-img-slider').slick('slickGoTo', $('.product-img-slider [data-id='+data.image_id+']').data('slick-index'));
                    }
                }, 'json'
            )
                ;
            }
            else {
                var after_changed = false;
                _this.closest('.product-options-wrapper').find('.product-option').each(function () {
                    if (after_changed) {
                        $(this).attr('title', "{{ trans('store.product.option.select_first') }}").attr('data-original-title', "{{ trans('store.product.option.select_first') }}").html('<option value="">' + $(this).data('group') + '</option>');
                    }
                    if ($(this).attr('id') == _this.attr('id')) {
                        after_changed = true;
                    }
                });
            }
        });

        function open_select(selector) {
            var element = $(selector)[0], worked = false;
            if (document.createEvent) { // all browsers
                var e = document.createEvent("MouseEvents");
                e.initMouseEvent("mousedown", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                worked = element.dispatchEvent(e);
            } else if (element.fireEvent) { // ie
                worked = element.fireEvent("onmousedown");
            }
            if (!worked) { // unknown browser / error
                alert("It didn't worked in your browser.");
            }
        }
    })();
});

function product_display(cookie_type,setCookie) {
    cookie_type = cookie_type || readCookie('display') || 'grid' ;
    setCookie = setCookie || false;
    if(cookie_type == null) return;
    var $products = $(".product:not('.fixed')");
    if(cookie_type=='list') {
        $products.removeClass("grid").addClass("list");
        $products.children('div').css('height','auto');

        if(setCookie === true){
            createCookie('display','list');
        }
    } else if(cookie_type =='grid') {
        $products.removeClass("list").addClass("grid");
        $products.children('div').css('height','100%');
        if($.fn.matchHeight){
            $.fn.matchHeight._update();
        }
        if(setCookie === true){
            createCookie('display','grid');
        }

    }
}

function createCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    }
    else var expires = "";
    document.cookie = name+"="+value+expires+"; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name,"",-1);
}

function number_format(val){
    while (/(\d+)(\d{3})/.test(val.toString())){
        val = val.toString().replace(/(\d+)(\d{3})/, '$1'+','+'$2');
    }
    return val;
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}